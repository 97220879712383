import React from 'react';
import styled, { css } from 'styled-components';
import GatsbyImage from 'gatsby-image/withIEPolyfill';
import { FluidObject } from 'gatsby-image';
import { Image } from 'src/infrastructure/gatsby/types/image';
import { media } from 'src/views/theme';
import { Svg } from 'src/infrastructure/gatsby/types/svg-type';
import RichTextTheme
  from '@shipae/components-sandbox/component/rich-text/theme';
import SmartLink from '../smart-link';

type SectionProps = {
  pdng?: string,
};
export const Section = styled.section<SectionProps>`
  padding: ${ (pr) => pr.pdng };
`;

type ImageSvgProps = {
  width?: string;
}
const ImageSvg = styled.img<ImageSvgProps>`
  width: ${ (pr) => pr.width || '44rem' };
  height: auto;
  margin: 0 auto;

  ${ media.md } {
    width: 100%;
  }
`;
type ImageProps = {
  fluid: FluidObject | FluidObject[];
  width?: string;
}
const ImageRegular = styled(GatsbyImage)<ImageProps>`
  width: ${ (pr) => pr.width || '44rem' };
  height: auto;
  margin: 0 auto;

  ${ media.md } {
    width: 100%;
  }
`;
export const ImageBg = GatsbyImage;

type ImageNodeProps = {
  mediaContent: Image & Svg,
  width?: string,
  pos: string,
};
export const ImageNode: React.FC<ImageNodeProps> = ({
  mediaContent,
  width,
  pos,
}) => {
  const objectFit = pos === 'bg' ? 'cover' : 'contain';

  if (mediaContent?.localFile?.childImageSharp?.fluid) {
    return pos === 'bg' ? (
      <ImageBg
        fluid={mediaContent?.localFile?.childImageSharp?.fluid}
        alt={mediaContent?.alternativeText || ''}
        objectFit={objectFit}
        objectPosition="center center"
        loading="eager"
        fadeIn={false}
      />
    ) : (
      <ImageRegular
        fluid={mediaContent?.localFile?.childImageSharp?.fluid}
        alt={mediaContent?.alternativeText || ''}
        objectFit={objectFit}
        objectPosition="center center"
        width={width}
        loading="eager"
        fadeIn={false}
      />
    );
  }
  if (
    !mediaContent?.localFile?.childImageSharp?.fluid
    && mediaContent?.localFile?.publicURL
  ) {
    return (
      <ImageSvg
        src={mediaContent.localFile?.publicURL}
        alt={mediaContent.alternativeText || ''}
        loading="lazy"
        width={width}
      />
    );
  }

  return null;
};

type CtaContainerProps = {
  clr?: string,
  mobileClr?: string,
};
const CtaContainerColor = css<CtaContainerProps>`
  & > a {
    color: ${ (pr) => pr.clr };

    ${ media.md } {
      color: ${ (pr) => pr.mobileClr };
    }
  }
`;
export const CtaContainer = styled.div<CtaContainerProps>`
  margin-top: 5rem;

  & > *:not(:last-child) {
    margin-right: 3rem;
  }

  ${ (pr) => (pr.clr || pr.mobileClr) && CtaContainerColor }

  ${ media.md } {
    display: flex;
    flex-direction: column;
    padding: 0 calc((100% - var(--container-width)) / 2);

    margin-top: 3rem;
    margin-bottom: 5rem;

    & > *:not(:last-child) {
      margin: 0 0 3rem;
    }
  }
`;
type CtaButtonContainerProps = {
  bgcolor?: string;
};
export const CtaButtonContainer = styled.div<CtaButtonContainerProps>`
  & button, & a {
    background-color: ${ (pr) => pr.bgcolor };
  }
`;

export const RichTextThemeEx = {
  ...RichTextTheme,
  Main: styled(RichTextTheme.Main)`
    margin-top: 2.5rem;

    & p {
      color: inherit;
    }

    & img {
      display: inline; // unset slick override
    }
  `,
};

export const StyledArrowLink = styled(SmartLink)`
  margin-top: 3rem;
  ${ media.sm } {
    text-align: center;
  }
`;
