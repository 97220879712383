import React, { useContext } from 'react';
import { Media } from 'src/infrastructure/gatsby/types/media';
import MediaSectionComponent
  from '@shipae/components-sandbox/component/media-section';
import ReactMarkdown from 'markdown-to-jsx';
import PrimaryButton
  from '@shipae/components-sandbox/component/buttons/primary';
import RichText
  from '@shipae/components-sandbox/component/rich-text';
import { PageContext } from 'src/views/templates/context';
import { calculateBreadcrumbs } from 'src/views/utils/calculate-breadcrumbs';
import { useTranslation } from 'src/views/misc/localization';

import { jetBlack } from '@shipae/components-sandbox/component/colors';
import SmartLink from 'components/smart-link';
import GtArrowIcon from '@shipae/components-sandbox/component/icons/gt-arrow';
import {
  Section,
  ImageNode,
  CtaContainer,
  CtaButtonContainer,
  RichTextThemeEx,
  StyledArrowLink,
} from './styles';
import gloabalReactMdOptions from '../shared/react-makdown-options';
import { ROUTES } from '../../constants/ROUTES';

const MediaSection: React.FC<Media> = ({
  id,
  sectionId,
  content,
  mediaSide,
  mediaWidth,
  // layout, To later toggle between MediaSection and BackgroundImage
  showBreadcrumbs,
  mobileHideCallToActions,
  mobileHideMedia,
  mobileImageFirst,
  contentFontColor,
  contentHasBreadcrumbs,
  topBottomPadding,
  mobileFontColor,
  callToAction,
  media: mediaContent,
}) => {
  const pageContext = useContext(PageContext);
  const { locale, t } = useTranslation();
  const tbPadding = topBottomPadding || '9rem';
  const SectionPadding = mediaSide === 'bg'
    ? ''
    : `${ tbPadding } calc((100% - var(--container-width)) / 2)`;
  const contentPadding = mediaSide === 'bg'
    ? `${ tbPadding } calc((100% - var(--container-width)) / 2)`
    : '';

  return (
    <Section
      id={sectionId}
      pdng={SectionPadding}
      data-test="section-media"
      data-ga={`MediaSection-${ id || '#' }`}
    >
      <MediaSectionComponent
        breadcrumbsHome={{
          label: t('home'),
          url: ROUTES[locale].HOME,
        }}
        breadcrumbsLinks={
          showBreadcrumbs
            ? calculateBreadcrumbs({
              parentTitle: pageContext.pageSettings.parentPageTitle,
              parentPath: pageContext.pageSettings.parentPagePath,
              title: pageContext.title,
            })
            : null
        }
        linkComponent={SmartLink}
        media={{
          node: (
            <ImageNode
              mediaContent={mediaContent}
              pos={mediaSide}
              width={mediaWidth}
            />
          ),
          width: mediaWidth || '44rem',
          position: mediaSide,
        }}
        content={{
          hasBreadcrumbs: contentHasBreadcrumbs,
          padding: contentPadding,
          fontColor: contentFontColor,
        }}
        mobile={{
          hideCta: mobileHideCallToActions,
          hideMedia: mobileHideMedia,
          imageBelowContent: !mobileImageFirst,
          fontColor: mobileFontColor,
        }}
        cta={callToAction && callToAction.length > 0 && (
          <CtaContainer clr={contentFontColor} mobileClr={mobileFontColor}>
            {callToAction ? callToAction.map((cta) => (
              /* eslint-disable-next-line no-nested-ternary */
              cta.type === 'button' ? (
                cta.color ? (
                  <CtaButtonContainer bgcolor={cta.color}>
                    <PrimaryButton
                      key={cta.id}
                      href={cta.link}
                      linkComponent={SmartLink}
                    >
                      {cta.label}
                    </PrimaryButton>
                  </CtaButtonContainer>
                ) : (
                  <PrimaryButton
                    key={cta.id}
                    href={cta.link}
                    linkComponent={SmartLink}
                  >
                    {cta.label}
                  </PrimaryButton>
                )
              ) : (
                cta.type === 'underlineLink' ? (
                  <SmartLink
                    key={cta.id}
                    href={cta.link || '#'}
                  >
                    {cta.label}
                  </SmartLink>
                ) : (
                  <StyledArrowLink
                    key={cta.id}
                    href={cta.link || '#'}
                  >
                    <GtArrowIcon
                      direction="bottom"
                      color={cta.color || jetBlack()}
                    />
                  </StyledArrowLink>
                )
              )
            )) : null}
          </CtaContainer>
        )}
      >
        <RichText
          theme={RichTextThemeEx}
        >

          <ReactMarkdown options={gloabalReactMdOptions}>
            {content || ''}
          </ReactMarkdown>
        </RichText>
      </MediaSectionComponent>
    </Section>
  );
};

export default MediaSection;
